<script setup>
import {Head} from "@inertiajs/vue3";
</script>

<template>
    <Head title="Maintenance Mode"/>
    <v-container class="pa-0 ma-0 d-flex align-center overflow-hidden" fluid>
        <v-card flat color="primary" class="main-header border-b-xl border-t-xl overflow-hidden d-flex w-100">
            <v-row class="px-6 d-flex align-center">
                <v-col class=" d-flex align-center justify-center text-center">
                    <div>
                        <img src="/static/images/ibwa_round.png" alt="ibwa-logo" height="120"/>
                        <h1>I.B Whistler</h1>
                        <p class="text-text">Artist & Illustrator</p>
                    </div>
                    <div class="banner-information font-size-20">
                        <p class="text-accent">We're sorry!</p>
                        <p class="text-accent my-3">Our shop is currently under maintenance, please come back soon!</p>
                        <p class="text-accent">Visit our official
                            <a href="https://www.facebook.com/ibwhistlerartist" class="text-decoration-underline text-text">
                                 Facebook page
                            </a>
                            &nbsp;for updates.
                        </p>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
    <v-layout>
        <v-main
            class="d-flex align-center justify-center"
            style="min-height: 480px;"
        >
            <v-row>
                <v-col cols="4"></v-col>
                <v-col cols="4" class="px-15">
                    <slot />
                </v-col>
                <v-col cols="4"></v-col>
            </v-row>
        </v-main>
    </v-layout>
</template>

<script>
export default {
    data() {
        return {
            drawer: true,
            rail: true,
        };
    },
};
</script>

<style>
html {
    overflow: hidden !important;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

html::-webkit-scrollbar {
    width: 0;
    height: 0;
}
</style>

<style scoped>

.v-container {
    height: 100vh;
    margin: 0 !important;
}

.main-header {
    height: 300px;
    border-top-color: rgb(var(--v-theme-text)) !important;
    border-bottom-color: rgb(var(--v-theme-text)) !important;
}
</style>
